.listOptions {
  @apply absolute top-[52px] z-60 min-w-[220px] rounded-2xl bg-white p-4 text-base shadow-[0px_0px_15px_rgba(160,160,160,0.25)] transition duration-300 ease-in-out focus:outline-none sm:text-sm;
  &::before {
    content: "";
    position: absolute;
    top: -1rem /* -16px */;
    left: 0px;
    height: 1rem /* 16px */;
    width: 100%;
  }
  .listOption {
    @apply cursor-default select-none border-b-[0.5px] border-[#B5B5C3] pb-2.5 pt-2.5 first:pt-0 last:border-b-0 last:pb-0;
  }
}
.listButton {
  @apply py-[7px] pr-4;
}
.searchButton {
  z-index: 60;
  background-color: #00000066;
}
