.Index_openSearch__mDZT8 {
    top: -110px;
    height: 44px
}
.Index_listOptions__I1gdg{position:absolute;top:52px;z-index:60;min-width:220px;border-radius:1rem;--tw-bg-opacity:1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));padding:1rem;font-size:16px;line-height:26px;--tw-shadow:0px 0px 15px rgba(160,160,160,0.25);--tw-shadow-colored:0px 0px 15px var(--tw-shadow-color);box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);transition-property:color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;transition-duration:300ms;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)}.Index_listOptions__I1gdg:focus{outline:2px solid transparent;outline-offset:2px}@media (min-width: 640px){.Index_listOptions__I1gdg{font-size:0.875rem;line-height:1.25rem}}.Index_listOptions__I1gdg::before{content:"";position:absolute;top:-1rem;left:0px;height:1rem;width:100%}.Index_listOptions__I1gdg .Index_listOption__uvijX{cursor:default;-webkit-user-select:none;-moz-user-select:none;user-select:none;border-bottom-width:0.5px;--tw-border-opacity:1;border-color:rgb(181 181 195 / var(--tw-border-opacity));padding-bottom:0.625rem;padding-top:0.625rem}.Index_listOptions__I1gdg .Index_listOption__uvijX:first-child{padding-top:0px}.Index_listOptions__I1gdg .Index_listOption__uvijX:last-child{border-bottom-width:0px;padding-bottom:0px}.Index_listButton__jRuNV{padding-top:7px;padding-bottom:7px;padding-right:1rem}.Index_searchButton__3bwv1{z-index:60;background-color:rgba(0,0,0,.4)}
.Index_spacingTop__CcXMA {height: 68px
}@media (min-width: 1024px) {.Index_spacingTop__CcXMA {height: 104px
    }
}.Index_img404__ui1FU {padding-top: 2.5rem
}@media (min-width: 1024px) {.Index_img404__ui1FU {padding-top: 171px
    }.Index_img404__ui1FU {padding-bottom: 75px
    }
}.Index_title404__FH2vI {margin-top: 1.5rem;font-size: 1.875rem;font-weight: 600;line-height: 50px;--tw-text-opacity: 1;color: rgb(0 42 102 / var(--tw-text-opacity))
}@media (min-width: 1024px) {.Index_title404__FH2vI {margin-top: 2.5rem
    }.Index_title404__FH2vI {font-size: 48px;line-height: 68px
    }.Index_title404__FH2vI {letter-spacing: 0.25px
    }
}.Index_normal404__rskP_ {padding-top: 2.5rem
}@media (min-width: 1024px) {.Index_normal404__rskP_ {padding-top: 171px
    }.Index_normal404__rskP_ {padding-bottom: 75px
    }
}
